<template>
 <router-view/>
</template>

<script setup>
  import { watch, onMounted } from 'vue';
  import {useI18n} from 'vue-i18n'
  const { locale, t } = useI18n();


  const ApplyLangue = ()=>{
    if(!localStorage.getItem("lngAb")){
         localStorage.setItem("lngAb", 'Fr');
     }else{
       if(locale.value == "Ar"){
         document.body.style.direction = "rtl"
       }else if (locale.value == "Fr"){
         document.body.style.direction = "ltr"
       }
     }
  }

  watch(locale,(ov,nv)=>{
    ApplyLangue()
    if (locale.value == "Ar"){
      document.body.style.direction = "rtl"
    }
    else{
      document.body.style.direction = "ltr"
    }
    })

  onMounted(()=>{
    ApplyLangue()

   })

</script>


<style scoped>
  body{
    background-color: #EEEEEE;
  }
</style>
