import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/style.css'
import Fr from '@/locale/Fr.json'
import Ar from '@/locale/Ar.json' 
import { createI18n } from 'vue-i18n'
import { createHead } from '@vueuse/head';
import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const head = createHead();

const vuetify = createVuetify({
  components,
  directives,
})


const i18n = createI18n({
    locale:localStorage.getItem("lngAb") || 'Fr',
    legacy: false,
    messages:{
        Fr:Fr,
        Ar:Ar
    }
  })

createApp(App).use(vuetify).use(store).use(i18n).use(router).use(head).mount('#app')
