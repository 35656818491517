import axiosClient from '/src/axiosClient'


 async function verifyToken (){
    const token = localStorage.getItem("token")
    try{
        if(token){
            
            const response = await axiosClient.get('api/token/',{
                headers: {
                    Authorization: "Token "  + token
                }
            })
            console.log(response.data)
            return response.data;
        }
    }
    catch (error){
        console.error("Token verification error:", error);
            throw error; // Propagate the error
    }
    
        
}


export default verifyToken;