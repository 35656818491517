import verifyToken from "./VerifyToken";

async function  checkAuthentication ()  {
    try {
        const isAuthenticated = await verifyToken();
        return isAuthenticated;
    } catch (error) {
        console.error('Token verification error:', error);
        return false; // Return false in case of an error
    }
};


export default checkAuthentication;