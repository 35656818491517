import { createRouter, createWebHistory } from 'vue-router'
import checkAuthentication from '@/utils/CheckAuthentification.js';

const routes = [
  {
    path:'/',
    name:'Home',
    component : () => import ('@/views/Home.vue'),
    meta:{
      title:'Parcours Differents',
      description: "Bienvenue sur Parcours Différents, un lieu de bienveillance où nous connectons les parents d'enfants exceptionnels avec des experts, des associations et les ressources nécessaires pour offrir le meilleur soutien possible. Rejoignez notre communauté et découvrez comment, ensemble, nous pouvons changer de regard sur le handicap mental !",
    }
  },
  {
    path: '/forum',
    name: 'Forum',
  
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Forum/Forums.vue'),
    meta:{
      title:'Forum',
      description: "Bienvenue sur Parcours Différents, un lieu de bienveillance où nous connectons les parents d'enfants exceptionnels avec des experts, des associations et les ressources nécessaires pour offrir le meilleur soutien possible. Rejoignez notre communauté et découvrez comment, ensemble, nous pouvons changer de regard sur le handicap mental !",
    }
  },
  {
    path:'/sujet/:slug?',
    name:'Sujet',
    component: () => import (/* webpackChunkName: "about" */ '@/views/Forum/SingleSujet.vue')
  },
  {
    path:'/register',
    name:'Register',
    component: () => import ('@/views/Profil/Register.vue')
  },
  {
    path:'/associations',
    name:'Associations',
    component: () => import ('@/views/Associations/Associations.vue')
  },
  {
    path:'/medecins',
    name:'Medecins',
    component: () => import ('@/views/Medecins/Medecin.vue')
  },
  {
    path:'/blog',
    name:'Blog',
    component: () => import ('@/views/Blog/Blog.vue')
  },
  {
    path:'/article/:slug',
    name:'SingleBlog',
    component: () => import ('@/views/Blog/SingleBlog.vue')
  },
  {
    path:'/register_confirmation_email',
    name:'RConfirmationEmail',
    component: () => import ('@/views/Profil/RegisterConfirmEmail.vue')
  },
  {
    path:'/change_password',
    name:'ChangePassword',
    component: () => import ('@/views/Profil/ChangePassword.vue'),
    beforeEnter: async(to,from,next)=>{
      let isAuthenticated = await checkAuthentication();
      if(isAuthenticated){
        next()
      }else{
        next('/login')
      }
    }
  },
  {
    path:'/login',
    name:'Login',
    component: () => import ('@/views/Profil/Login.vue')
  },
  {
    path:'/profil/:id',
    name:'Profil',
    component: () => import ('@/views/Profil/Profil.vue')
  },
  {
    path: '/reset_password_email',
    name: 'ResetPassEmail',
    component: () => import( '@/views/Profil/ForgotPasswordEmail.vue')
  },
  {
    path: '/reset_password_change',
    name: 'FormNewPwReset',
    component: () => import( '@/views/Profil/FormNewPwReset.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from)=>{
  document.title = to.meta?.title ?? 'Parcours Differents'
})


export default router
